<template>
    <div style="background-color:#E9EAEC">
        <v-row style="padding:5%">
            <v-col>
                <p class="letterType" style="font-size:40px">
                    Descuentos para todas las organizaciones sin fines de 
                    lucro y MIPES en la contrucción de sus proyectos tecnológicos.
                </p><br>
                <p class="letra" style="text-align:justify">
                    Queremos ser participes en la construcción de una sociedad
                    más educada, limpia y con más oportunidades desarrollando tecnología acorde, siendo partner
                    de quienes luchan por ello día a día.
                </p>
            </v-col>
            <v-col>
                <center>
                    <img width="90%" src="../img/apoyo.png" alt="">
                </center>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style>
.backEcology{
background: rgb(207,219,124);
background: -moz-linear-gradient(185deg, rgba(207,219,124,1) 11%, rgba(233,234,236,1) 11%);
background: -webkit-linear-gradient(185deg, rgba(207,219,124,1) 11%, rgba(233,234,236,1) 11%);
background: linear-gradient(185deg, rgba(207,219,124,1) 11%, rgba(233,234,236,1) 11%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cfdb7c",endColorstr="#e9eaec",GradientType=1);
}
</style>