<template>
    <v-container style="color:#1B244F;padding:8%">
        <v-row style="margin-top:-5%">
            <h3 class="letterType" style="font-size:6vw">Desarrollo a medida</h3> 
            <v-divider class="latidos" vertical style="margin-left:0%;border:8px solid #9b1616"></v-divider>
            <h4 class="letra" style="color:#9b1616;padding:0%;font-size:5vh">¿Por qué debería importarte?</h4>
        </v-row><br>
        <v-row>
            <v-col cols="12" xs="12" md="7" lg="7" xl="5">
                <p class="letra" style="text-align:justify">
                    Probablemente ni siquiera sepas que requieres un producto que se ajuste totalmente a tu negocio.
                    Es normal considerando la gran cantidad de ofertas que parecieran ajustarse a tu problema y el
                    desconocimiento existente en la utilidad y beneficios de la tecnología apropiada para resolverlo. <br><br>
                    En un desarrollo a medida todo se hace <b>desde cero</b> considerando cada uno de los factores que 
                    puedan afectar a tu negocio, buscando utilizar las tecnologías que mejor se adapten a la solución,
                    construyendo un software auténtico, que contenga en cada una de sus funciones y diseño la esencia 
                    de tu marca, producto, negocio, idea, corporación, organización o a lo que quieras digitilazar por medio de un
                    software, página web o app.
                </p>
            </v-col>
            <v-col cols="12" xs="12" md="5" lg="5" xl="7">
                <p class="letterType" style="font-size:4.5vh">
                    Nos gustan los retos y desarrollar nuevas experiencias en cada uno de ellos, es la forma en que decidimos seguir aprendiendo.
                </p>
            <center style="margin-top:8%">
                <v-btn class="letterType" to="/quote" dark color="#9b1616" style="font-size:4vh" rounded x-large>cotiza ahora</v-btn>
            </center>
            </v-col>
        </v-row>
        <br><br>
    </v-container>
</template>

<script>
export default {
    data: () =>({
        
    })
}
</script>

<style>
</style>